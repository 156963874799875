import React, { useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { v4 } from "uuid";
import FormControl from "@material-ui/core/FormControl";
import { Button, TextField, Box } from "@material-ui/core";

export default () => {
  const typingTimer = React.useRef(null);
  const [state, setState] = useState({
    company: "",
    name: "",
    email: "",
    note: "",
    phone: "",
    location: "",
    teamSize: "",
  });
  const [isBusy, setBusy] = useState(false);

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      setBusy(true);
      axios.put(
        `https://lablog-62234.firebaseio.com/request-demo/+${v4()}.json`,
        { data: state }
      );
      navigate("/form-submitted/", state);
    },
    [state]
  );

  const enrichData = async (email) => {
    try {
      const ip = await axios.get("https://api.ipify.org");
      const res = await axios.get(
        `https://person.clearbit.com/v2/combined/find?email=${email}&ip_address=${ip?.data}`,
        {
          headers: {
            Authorization: "Bearer sk_7165ca8344857e279029d033a760c739",
          },
        }
      );
      setState((s) => ({
        ...s,
        company: res?.data?.company?.name || "",
        name:
          res?.data?.person?.name?.fullName ||
          res?.data?.person?.name?.givenName ||
          "",
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const handleValueChange = React.useCallback((event) => {
    if (!event || !event.target) return;
    const { name, value } = event.target;
    setState((s) => ({
      ...s,
      [name]: value,
    }));
    if (
      name === "email" &&
      value.indexOf("@") > 0 &&
      value.indexOf(".") > 0 &&
      value.indexOf("gmail") == -1
    ) {
      if (typingTimer.current) clearTimeout(typingTimer.current);
      typingTimer.current = setTimeout(enrichData, 300, value);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} disabled={isBusy}>
      <Box pl={2} pr={2} mt={2.0}>
        <TextField
          id="email-input"
          name="email"
          onChange={handleValueChange}
          value={state.email}
          aria-describedby="email-input"
          label="Email"
          variant="outlined"
          fullWidth
          autoComplete="new-password"
          InputLabelProps={{ shrink: true }}
          size="small"
          margin="normal"
          placeholder="Type your email address here..."
        />

        <TextField
          id="name-input"
          variant="filled"
          name="name"
          value={state.name}
          onChange={handleValueChange}
          aria-describedby="name-input"
          label="Full name"
          variant="outlined"
          fullWidth
          autoComplete="new-password"
          InputLabelProps={{ shrink: true }}
          size="small"
          margin="normal"
          placeholder="Your name"
        />

        <TextField
          id="company-input"
          name="company"
          value={state.company}
          onChange={handleValueChange}
          aria-describedby="company-input"
          label="Organization"
          variant="outlined"
          fullWidth
          margin="normal"
          autoComplete="new-password"
          InputLabelProps={{ shrink: true }}
          size="small"
          placeholder="Company or institute name"
        />

        <TextField
          id="teamSize-input"
          name="teamSize"
          type="number"
          value={state.teamSize}
          required
          onChange={handleValueChange}
          aria-describedby="teamSize-input"
          label="Team size"
          variant="outlined"
          fullWidth
          margin="normal"
          autoComplete="new-password"
          InputLabelProps={{ shrink: true }}
          size="small"
          placeholder="Number of users"
        />

        <Box mt={2}>
          <FormControl variant="filled" fullWidth size="small" margin="dense">
            <TextField
              label="Comments"
              value={state.note}
              multiline
              required
              rows={6}
              rowsMax={6}
              variant="outlined"
              placeholder="What are you hoping to accomplish with LabLog?"
              InputLabelProps={{ shrink: true }}
              name="note"
              onChange={handleValueChange}
            />
          </FormControl>
        </Box>
      </Box>
      <Box marginTop="30px" pl={2} pr={2}>
        <Button
          disabled={
            isBusy ||
            !state.email ||
            state.email.indexOf("@") < 0 ||
            !state.name ||
            !state.company ||
            state.email.indexOf("gmail") > -1
          }
          disableElevation
          variant="contained"
          type="submit"
          color="primary"
          fullWidth
          style={{ textTransform: "none" }}
        >
          Request a demo
        </Button>
      </Box>
    </form>
  );
};
