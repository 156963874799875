import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import { grey, indigo } from "@material-ui/core/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardReview from "./CardReview/index";
import { InlineWidget } from "react-calendly";
import SEO from "./seo";
import DemoRequestForm from "./DemoRequestForm";
import Fade from "react-reveal/Fade";

export default () => {
  const matches = useMediaQuery("(min-width:1350px)");
  const smMatch = useMediaQuery("(min-width:1100px)");
  const xsMatch = useMediaQuery("(min-width:800px)");

  return (
    <>
      <SEO
        title="Request Demo | Ask Questions"
        description="Schedule a live demo for your team to see LabLog in action and have your sales questions answered."
      />

      <Box
        style={{
          paddingBottom: "0px",
          width: "100%",
        }}
      >
        <Box position="relative">
          <Box
            pt={8}
            pl={matches ? "150px" : "25px"}
            pr={6}
            pb={13}
            style={{
              background: "linear-gradient(90deg, #4b6cb7 0%, #182848 100%)",
            }}
          >
            <Box
              color="#FFFFFF"
              fontSize={xsMatch ? "4em" : "2em"}
              fontWeight="550"
            >
              See LabLog
            </Box>
            <Box
              color="#FFFFFF"
              fontSize={xsMatch ? "4em" : "2em"}
              fontWeight="550"
            >
              in action
            </Box>
            <Box color="#FFFFFF" fontWeight="350" textAlign="left" mt={1.25}>
              Get a product overview, discuss pricing options, and have your
              questions answered - all in less than one hour!
            </Box>
          </Box>
          {smMatch ? (
            <Box
              position="absolute"
              right={matches ? "150px" : "25px"}
              top="120px"
            >
              <Fade right>
                <Box
                  overflow="hidden"
                  boxShadow="0px 0px 11px 1px rgba(0,0,0,0.69)"
                  bgcolor="#FFFFFF"
                  borderRadius="5px"
                >
                  <Box fontSize="large" pl={3} pt={3} fontWeight="650">
                    Yes, I want a demo for my team
                  </Box>
                  <Box p={3} pt={0} width="420px">
                    <DemoRequestForm />
                  </Box>
                </Box>
              </Fade>
            </Box>
          ) : (
            <Fade right>
              <Box
                boxShadow="0px 0px 11px 1px rgba(0,0,0,0.69)"
                top="50px"
                bgcolor="#FFFFFF"
                borderRadius="5px"
                overflow="hidden"
              >
                <Box
                  fontSize="large"
                  pl={3}
                  pt={3}
                  fontWeight="650"
                  textAlign="center"
                >
                  Yes, I want a demo
                </Box>
                <Box
                  p={3}
                  pt={0}
                  width="100%"
                  display="flex"
                  justifyContent="center"
                >
                  <Box width="80">
                    <DemoRequestForm />
                  </Box>
                </Box>
              </Box>
            </Fade>
          )}
        </Box>

        <Box
          p={6}
          pt={2}
          pl={matches ? "150px" : "25px"}
          width={matches ? "50%" : "100%"}
        >
          <Box color="#3d3d3d" component="p" textAlign="left">
            Take a look at how LabLog&#39;s easy-to-use interface, FDA
            compliance, intuitiveness and integrations set us apart from the
            competition.
          </Box>

          <Box
            marginBottom="0"
            textAlign="left"
            component="h5"
            fontWeight="600"
            color="#3d3d3d"
            fontSize="h5.fontSize"
          >
            This demo is customized around you
          </Box>
          <Box color="#3d3d3d" component="p" textAlign="left">
            Get answers to your specific questions, and find out why LabLog is
            the right choice for your lab.
          </Box>

          <Box
            marginBottom="0"
            textAlign="left"
            component="h5"
            fontWeight="600"
            color="#3d3d3d"
            fontSize="h5.fontSize"
          >
            &#10004; Data Storage
          </Box>
          <Box color="#3d3d3d" component="p" textAlign="left">
            Avoid hidden costs! Peace of mind with secure data storage and cloud
            computing built on Microsoft Azure cloud infrastructure. We are a
            Microsoft Partner. Cloud computing is getting cheaper, so why should
            your cloud-based notebook get more expensive?
          </Box>
          <Box
            marginBottom="0"
            textAlign="left"
            component="h5"
            fontWeight="600"
            color="#3d3d3d"
            fontSize="h5.fontSize"
          >
            &#10004; Analytics
          </Box>
          <Box color="#3d3d3d" component="p" textAlign="left">
            Gain meaningful insights with your data recorded in LabLog lab
            notebook app. Reduce waste and time to market for your new products.
          </Box>
          <Box
            marginBottom="0"
            textAlign="left"
            component="h5"
            fontWeight="600"
            color="#3d3d3d"
            fontSize="h5.fontSize"
          >
            &#10004; FDA Compliance
          </Box>
          <Box color="#3d3d3d" component="p" textAlign="left">
            Peace of mind with our third-party verified FDA compliance
            assessments. Automate FDA compliance with audit reports, version
            control, signatures, system access control and more.
          </Box>
          <Box
            marginBottom="0"
            textAlign="left"
            component="h5"
            fontWeight="600"
            color="#3d3d3d"
            fontSize="h5.fontSize"
          >
            &#10004; Subscription Prices Fixed For Life
          </Box>
          <Box color="#3d3d3d" component="p" textAlign="left">
            30% Off for startups and small labs in the life sciences industry.
            We don&#39;t add extra charges. We don&#39;t bump up prices every
            year. One simple price plan helps you budget and grow your research
            activities fast.
          </Box>
          <Box color="#3d3d3d" mt={3} component="p" textAlign="left">
            See for yourself! Fill out the form on top of this page to schedule
            a free demo customized for your specific research needs, or book a
            time using the calendar below.
          </Box>
        </Box>

        <Box width={smMatch ? "45%" : "100%"} margin="auto">
          <CardReview
            icon={<FormatQuoteIcon htmlColor={indigo[200]} fontSize="large" />}
            text="LabLog is extremely well designed, has a clean and user friendly interface, and
                  includes incredible features for organizing my experiments. Not only does it help
                  me be more efficient in the lab, it also helps to ensure FDA compliance for
                  electronic records."
            authorName="Veronica Adams"
            authorTitle="National Cancer Institute, the National Institutes of Health"
            authorPhoto={{
              src: "",
            }}
          />
        </Box>
      </Box>
      <Box bgcolor={grey[100]}>
        <Container maxWidth="md">
          <Box mt={5} fontWeight="500" textAlign="center" fontSize="3em">
            Schedule Sales Demo
          </Box>
          <Box>
            <InlineWidget
              url="https://calendly.com/lablog/demo"
              styles={{ height: "720px" }}
            />
          </Box>
          <Box color="#3d3d3d" component="p" textAlign="left">
            LabLog was created by a team with experience at NIH, FDA and
            GlaxoSmithKline. Our team is based in Maryland and our goal is to
            provide the perfect solution backed by excellent customer service.
            &nbsp;
            <a href="/about/" target="_blank">
              Click here
            </a>{" "}
            here to learn more about the team behind LabLog or schedule a demo
            below to talk to us about your questions.
            <br />
            <br />
            <i>LabLog is registered trademark in the United States.</i>
          </Box>
        </Container>
      </Box>
    </>
  );
};
